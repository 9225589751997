import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Alert, Button, Collapse, Container, Form, Nav, Navbar, Spinner } from 'react-bootstrap';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginPageWithUserDetail } from './components/LoginPageWithUserDetail';
import React from 'react';
import ReactGA from "react-ga4" ;
import { jwtDecode } from 'jwt-decode';
import CustomerVehicleDetail from './components/CustomerVehicleDetail';
import { CustomerDriverDetail } from './components/CustomerDriverDetail';
import { CustomerVehicleDetailPart } from './components/CustomerVehicleDetailPart';
import Clock from './components/Clock';

export const MainContext = React.createContext();

ReactGA.initialize("G-YL3G85X4DV");

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPageWithUserDetail />,
  },
  {
    path: "/vehicleDetail/:id",
    element: <CustomerVehicleDetail />,
  },
  {
    path: "/driverDetail",
    element: <CustomerDriverDetail />,
  },
  {
    path: "/vehicleDetailPart",
    element: <CustomerVehicleDetailPart />,
  }
]);

const App = () => {
  var [accessToken, setAccessToken] = useState('');
  var [sasToken, setSasToken] = useState('');
  var [progress, setProgress] = useState(false);
  var [errorMessage, setErrorMessage] = useState('');
  const [reactGa] = useState(ReactGA);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('kentist-musteri-darkMode') === "true");
  const [timeoutId, setTimeoutId] = useState('');
  const [signedInUserEmail, setSignedInUserEmail] = useState('');

  const clearErrorMessage = () => setErrorMessage("");
  const setCurrentErrorMessageWithTimeout = (err) => {
    clearErrorMessage();
    clearTimeout(timeoutId);
    if (err.status === 401)
    {
      setErrorMessage('Kullanıcı adı veya şifre hatalı. Lütfen tekrar deneyiniz.');
    }
    else
    {
      setErrorMessage('Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
    }

    var timeout = setTimeout(clearErrorMessage, 7000);
    setTimeoutId(timeout.toString());
  }

  useEffect(() => {
    if (darkMode)
    {
      localStorage.setItem('kentist-musteri-darkMode', 'true');
      document.body.setAttribute('data-bs-theme', 'dark');
    }
    else
    {
      localStorage.setItem('kentist-musteri-darkMode', 'false');
      document.body.removeAttribute('data-bs-theme');
    }
  }, [darkMode]);

  useEffect(() => {
    // Get once from localStorage
    var token = localStorage.getItem('kentist-musteri-accessToken') ?? '';
    if (token === '')
    {
      return;
    }

    var decodedToken = jwtDecode(token);
    var date = new Date();
    // console.log(decodedToken.exp, (date.getTime() + 5 * 60 * 1000) / 1000)
    // below code is checking if token is greater than current time + 5mins
    if (decodedToken.exp > ((date.getTime() + 5 * 60 * 1000) / 1000))
    {
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    if (accessToken)
    {
      let decoded = jwtDecode(accessToken);
      setSignedInUserEmail(decoded?.email);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken)
      clearErrorMessage();
  }, [accessToken])

  const handleLogout = () => {
    localStorage.removeItem('kentist-musteri-accessToken');
    setAccessToken('');
  }

  return (
      <MainContext.Provider value={{
        accessToken: accessToken,
        setAccessToken: setAccessToken,
        darkMode: darkMode,
        sasToken: sasToken,
        setSasToken: setSasToken,
        setProgress: setProgress,
        progress: progress,
        setErrorMessage: setCurrentErrorMessageWithTimeout,
        reactGa: reactGa,
        signedInUserEmail: signedInUserEmail
      }}>
        <div className='fade' style={{height: '100%', opacity: progress ? 0.5 : 1}}>
          {progress ? <div className='spinner'><Spinner animation="border" /></div> : null}
          <Navbar collapseOnSelect expand="lg" bg='dark'>
            <Navbar.Brand href="/" className="custom-navbar" style={{backgroundColor: 'white', marginLeft: 10}}>
            <img
              src={`${process.env.PUBLIC_URL}/kentist-logo.png`}
              alt="Kentist Turizm"
              style={{width: '100px' }} // Adjust these styles as needed
            />
          </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="w-100 d-flex justify-content-between">
                <Nav className='me-auto'></Nav>
                {accessToken ? <Clock /> : null}
                <div style={{display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon color='white' icon={faSun} />
                  <Form.Check
                    style={{color: "white", marginLeft: 10}}
                    type="switch"
                    id="custom-switch"
                    checked={darkMode}
                    onChange={() => setDarkMode(!darkMode)}
                  />
                  <FontAwesomeIcon color='white' style={{marginRight: 10}} icon={faMoon} />
                </div>
                {accessToken ? <Button size="sm" variant="secondary" onClick={() => handleLogout()} style={{marginRight: 10}}>Çıkış yap</Button> : null}
              </div>
            </Navbar.Collapse>
          </Navbar>
          <Navbar fixed="bottom" bg="dark" variant="dark">
            
              <div style={{paddingLeft: 5, flexGrow: 1}}>
                <Navbar.Brand>
                  <img src={`${process.env.PUBLIC_URL}/tulparfooterinverted.png`} className="bottom-brand-logo" alt="Transoft Yazılım" />
                </Navbar.Brand>
                <span className="bottom-nav-brand">'Kaplan Consulting LLC' markasıdır. Tüm hakları saklıdır.</span>
                
              </div>
              <div style={{paddingRight: 5, color: 'white'}}>
              <span className="bottom-nav-brand">{new Date().toLocaleDateString("tr-TR", { weekday: 'long' })} - {new Date().toLocaleDateString("tr-TR")}</span>
              </div>
          </Navbar>
          
            <Collapse in={errorMessage !== ''}>
              <div>
                <Alert dismissible variant='danger' onClose={() => clearErrorMessage()}>
                  {errorMessage}
                </Alert>
              </div>
            </Collapse>
              {accessToken ? <div style={{height: '100%'}}><RouterProvider router={router} /></div> : <LoginPageWithUserDetail />}
        </div>
      </MainContext.Provider>
  );
}

export default App;
